import React, { useEffect, useRef, useState } from 'react';
import {useAuth} from '../hooks/appHooks';
import logo from '../img/resta-logo.png';
import { useNavigate } from 'react-router-dom';
import {FaSignInAlt} from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from '../apis/axios';
import loginImage from "../img/login_bg.jpeg"
import { useTranslation } from 'react-i18next';
import RoleSelector from '../utils/RoleSelector';
const LOGIN_URL = "/auth/login";
const EVENTS_URL = "/events/active";

const Login = () => {
    const {setAuth} = useAuth()
    const emailRef = useRef(null);
    const eventRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate()
    const {t, i18n} = useTranslation();
    const tLogin = t("login")
    const [events, setEvents] = useState(null)
    const [isManager, setIsManager] = useState(false)
    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            const headers = {"Content-Type": "application/json"}
            try {
                if(!didCancel){
                    const result = await axios.get(EVENTS_URL, { headers });
                    setEvents(result.data.list);
                }
            } catch (error) {
                if(!didCancel){
                    console.log(error)
                }
            }
        }
        if(!isManager){
            fetchData()
        }

      return () => { didCancel=true }
    }, [isManager])
    
    const handleAnonimousLogin = async (e)=>{
        e.preventDefault();
        console.log("Selected Event is "+eventRef?.current?.value)
        try {
            const email = emailRef.current.value;
            const password = passwordRef.current.value;
            const eventId = eventRef?.current?.value;
            const response = await axios.post(LOGIN_URL,                
                JSON.stringify({email, password, eventId}),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(JSON.stringify(response?.data));

            localStorage.setItem("ecms_token", response?.data.token)
            localStorage.setItem("refresh_token", response?.data.refreshToken)
            setAuth(response?.data);
            navigate(eventId ? "/exhibitors" : "/super/events", { replace: true })
            toast.success('Login successfully');
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }
    console.log(events)
    return (
    <div className='cairo-font'>
        <ToastContainer/>
        <div className='flex justify-start items-center ps-10 h-screen login_bg'>
            <div className='flex flex-col justify-start w-full md:w-4/12 pe-10'>
                <RoleSelector isManager={isManager} onRoleChanged={(value)=>setIsManager(value)}/>
                <div className='flex justify-between items-center'>
                    <h2 className='uppercase'>
                        {tLogin.title}
                        {i18n.language==="en" && <sub className='m-2 bg-white font-extrabold p-2'>
                            {tLogin.loginTxt} <span><FaSignInAlt style={{display: 'inline'}} /></span>
                        </sub>}
                    </h2>
                    <img src={logo} alt="logo" className='my-3 w-[30%] bg-primary-color rounded-xl' width={150}/>
                </div>
                <h3 className="text-lg md:text-xl my-11 dark:text-white">
                    {tLogin.subtitle}
                </h3>
                <form className="space-y-4 md:space-y-2.5 py-2.5" onSubmit={handleAnonimousLogin} method='post'>
                    <div>
                        <label htmlFor="email" className="mb-2 text-lg ">{tLogin.emailLabel}</label>
                        <input ref={emailRef} type="email" name="email" id="email" className="sm:text-sm w-full p-2.5 shadow-lg" placeholder="name@company.com" required />
                    </div>
                    {!isManager&&
                    <div>
                        <label htmlFor="event" className="mb-2 text-lg ">{tLogin.eventLabel}</label>
                        {!events?"Loading...":
                        <select ref={eventRef} type="event" name="event" id="event" className="sm:text-sm w-full p-2.5 shadow-lg" placeholder="Riyadh" required >
                            {events.map((single)=>{
                                return <option key={single.eventId} value={single.eventId}>{i18n.language==="en"?single.enName:single.arName}</option>
                            })}
                        </select>
                        }
                    </div>}
                    <div>
                        <label htmlFor="password" className="mb-2 text-lg ">{tLogin.passwordLabel}</label>
                        <input ref={passwordRef} type="password" name="password" id="password" placeholder="••••••••" className="sm:text-sm w-full p-2.5 shadow-lg" required />
                    </div>
                    <button type="submit" className="w-full text-white bg-primary-color px-5 py-2.5 shadow-lg">{tLogin.loginTxt}</button>
                </form>
            </div>
            <div className='hidden md:flex h-screen w-full bg-primary-color'>
                <img className='w-full' src={loginImage} alt="Logo" />
            </div>
        </div>
    </div>
    )
}

export default Login