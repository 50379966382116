import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../../apis/useCheckRouteAuthorization'
import * as utils from '../../utils/FormUtils/ExhibitorFileUtils'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../../apis/axios';
import { useAuth } from '../../hooks/appHooks';

const EXHIBITOR_FILES_SAVE_INFO_URL = "/exhibitorFiles/save"

const AddOrEditExhibitorFiles = () => {
  useCheckRouteAuthorization();
  const { auth } = useAuth();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const fileInfo = t("fileInfo");
  const exhibitorFile = location.state?.exhibitorFile;
  const route = location.pathname.split("/")[2];
  const [isOptional, setIsOptional] = useState(!!exhibitorFile?.optional);
  useEffect(()=>{
    if((!exhibitorFile&&route==="edit")) navigate("/exhibitorFiles")
  }, [navigate, exhibitorFile, route])

  const [errors, setErrors] = useState();
  
    const handleAddOrEditExhibitorFile = async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        data.set("optional", isOptional)
        const formData = Object.fromEntries(data.entries());
        console.log(formData)
        try {
        await utils.validationSchema(fileInfo, exhibitorFile, t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        setErrors(null)
        const exhibitorFileData = new FormData()
        utils.fillExhibitorFileData(exhibitorFileData, formData, auth.event.eventId, exhibitorFile?exhibitorFile.fileId:null);
        const dataRows = Object.fromEntries(exhibitorFileData.entries());
        console.log(dataRows)
        const infoResponse = await axiosPrivate.post(EXHIBITOR_FILES_SAVE_INFO_URL, exhibitorFileData,
            {headers: {'Accept-Language': i18n.language}}
        );
        if(!exhibitorFile){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        }
        toast.success(infoResponse?.data.message);
        navigate(-1)
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
        toast.error(error.response?.data.message)
      }
    }

  return (
    <div>
      <ToastContainer/>
        <h2>{exhibitorFile?fileInfo.editFileTitle:fileInfo.addFileTitle} {exhibitorFile&&i18n.language==="ar"?exhibitorFile?.arTitle:exhibitorFile?.enTitle}</h2>
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditExhibitorFile} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="arTitle" className="text-lg">{fileInfo.arTitle?.label}</label>
                      <input type="text" name="arTitle" id="arTitle" defaultValue={exhibitorFile?.arTitle} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.arTitle?.label} />
                      {errors?.arTitle&&<div className='text-red-600'>{errors?.arTitle}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="enTitle" className="text-lg">{fileInfo.enTitle?.label}</label>
                      <input type="text" name="enTitle" id="enTitle" defaultValue={exhibitorFile?.enTitle} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.enTitle?.label} />
                      {errors?.enTitle&&<div className='text-red-600'>{errors?.enTitle}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="dueDate" className="text-lg">{fileInfo.dueDate?.label}</label>
                      <input type="datetime-local" name="dueDate" id="dueDate" defaultValue={exhibitorFile?.fdueDate} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.dueDate?.label} />
                      {errors?.dueDate&&<div className='text-red-600'>{errors?.dueDate}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="guidesFile" className="text-lg">{fileInfo.guidesFile?.label}</label>
                      <input type="file" name="guidesFile" id="guidesFile" className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.guidesFile?.label} />
                      {errors?.guidesFile&&<div className='text-red-600'>{errors?.guidesFile}</div>}
                    </div>
                    {/* <div className="md:w-1/4">
                      <label htmlFor="receiveEmail" className="text-lg">{fileInfo.receiveEmail?.label}</label>
                      <input type="text" name="receiveEmail" id="receiveEmail" defaultValue={exhibitorFile?.receiveEmail} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.receiveEmail?.label} />
                      {errors?.receiveEmail&&<div className='text-red-600'>{errors?.receiveEmail}</div>}
                    </div> */}
                    <div className="md:w-1/4 mt-6">
                      <label htmlFor="optional" className="text-lg">{fileInfo.optional?.label}</label>
                      <input type="checkbox" name="optional" id="optional" value={isOptional} onChange={(e)=>setIsOptional(e.target.checked)} checked={isOptional} className="bg-slate-100 m-2.5" placeholder={fileInfo.optional?.label} />
                      {errors?.optional&&<div className='text-red-600'>{errors?.optional}</div>}
                    </div>
                    <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    {/* <div className="md:w-1/4">
                      <label htmlFor="extension" className="text-lg">{fileInfo.extension?.label}</label>
                      <select name="extension" id="extension" defaultValue={exhibitorFile?.extension} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.extension?.label}>
                        <option value=".pdf,.pdf">.pdf,.pdf</option>  
                        <option value=".xlsx,.xls">.xlsx,.xls</option>  
                        <option value=".doc,.docx">.doc,.docx</option> 
                      </select>
                      {errors?.extension&&<div className='text-red-600'>{errors?.extension}</div>}
                    </div> */}
                    {/* <div className="md:w-1/4">
                      <label htmlFor="fileType" className="text-lg">{fileInfo.fileType?.label}</label>
                      <select name="fileType" id="fileType" defaultValue={exhibitorFile?.fileType} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={fileInfo.fileType?.label}>
                        <option value="SPACE_ONLY">SPACE_ONLY / مساحة فارغة</option>
                        <option value="STANDARD_BOOTH">STANDARD_BOOTH / تجهيزات موحدة</option>
                        <option value="OFFICIAL">OFFICIAL SPONSER / الراعي الرسمي</option>
                        <option value="DIAMOND">DIAMOND SPONSER / الراعي الماسي</option>
                        <option value="PLATINUM">PLATINUM SPONSER / الراعي البلاتيني</option>
                        <option value="GOLDEN">GOLDEN SPONSER / الراعي الذهبي</option>
                        <option value="SILVER">SILVER SPONSER / الراعي الفضي</option>
                        <option value="CO">CO_SPONSER / الراعي المشارك</option>
                      </select>
                      {errors?.fileType&&<div className='text-red-600'>{errors?.fileType}</div>}
                    </div> */}
                    {/* <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button> */}
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddOrEditExhibitorFiles