import *as Yup from 'yup'

export const initConstentDescription = (content)=>{
    return {
        arDescription: content?content?.arDescription:null,
        enDescription: content?content?.enDescription:null,
    }
}

export const validationSchema =(contentTexts, isEditing, requiredMessage)=> {return Yup.object({
    arTitle: Yup.string().required(requiredMessage),
    enTitle: Yup.string().required(requiredMessage),
    arDescription: Yup.string().nullable(),
    enDescription: Yup.string().nullable(),
    priority: Yup.number().typeError(contentTexts.priority?.onlyNumber)
    .min(1, "الترتيب يبدأ من 1")
    .max(15, "الترتيب ينتهي بـ15")
    .required(requiredMessage),
    img: Yup.mixed().when([], {
    is: () => !isEditing,
    then: ()=> Yup.mixed()
      .test(
        'fileSize',
        'حجم الصورة غير مدعوم',
        value => value.name==="" || (value && value.size <= 1024 * 1024 * 25) // 25 MB
      )
      .test(
        'fileType',
        `الملفات المدعومة png, jpg, jpeg فقط`,
        value => value.name==="" || (value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value?.type))
      ),
      otherwise: ()=> Yup.mixed().notRequired(), // No validation when editing
    }),
})}

export const fillContentData=(contentData, formData, screenId, contentId)=>{
    contentData.append("ContentPayload", JSON.stringify({
        contentId, screenId,
        arTitle: formData.arTitle,
        enTitle: formData.enTitle,
        arDescription: formData.arDescription,
        enDescription: formData.enDescription,
        priority: parseInt(formData.priority)
      }));
      contentData.append("ContentImg", formData.img);
}