import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import translationEN from './locale/en_translation.json';
import translationAR from './locale/ar_translation.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: "ar",
    lng: "ar",
    returnObjects: true,
    resources: {
        en: { translation: translationEN },
        ar: { translation: translationAR }
    }
});