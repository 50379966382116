import React, { useEffect, useState } from 'react'
import { IoIdCardSharp } from "react-icons/io5";
import { GiFiles } from "react-icons/gi";
import { BsFillPassportFill } from "react-icons/bs";
import {MdDelete, MdEdit, MdGroups, MdLock} from 'react-icons/md'
import { Link } from 'react-router-dom'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization'
import { useAuth, useExhibitorsList } from '../hooks/appHooks'
import AppLoading from '../utils/AppLoading'
import useAxiosFetchApi from '../hooks/useFetch'
import ConfirmationModal from './modals/ConfirmationModal'
import ChangePasswordModal from './modals/EditModal'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import { axiosPrivate } from '../apis/axios'
import ChangePasswordForm from '../utils/ChangePasswordForm'
const EXHIBITORS_LIST_URL = "/exhibitors/event"
const EXHIBITOR_DELETE_URL = "/exhibitors/delete"
const EXHIBITOR_CHANGE_PASSWORD_URL = "/auth/change/password"
const ExhibitorsList = () => {
  useCheckRouteAuthorization()
  const {auth} = useAuth()
  const {exhibitors, setExhibitorsList} = useExhibitorsList()
  const {t, i18n} = useTranslation();
  const exhibitorInfo = t("exhibitorInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({exhibitor: null, isShown: false});
  const [passwordModal, setPasswordModal] = useState({exhibitor: null, isShown: false});
  const [state, setUrl, setChangeData] = useAxiosFetchApi(null, {}, auth.token);
  
  useEffect(()=>{
    if(auth.event){
      setUrl(EXHIBITORS_LIST_URL.concat(`/${auth.event?.eventId}`))
    }
  },[setUrl, auth.event])
  useEffect(()=>{
    if(state.data?.list){
      setExhibitorsList(state.data?.list);
    }
  },[state.data?.list, setExhibitorsList])

  const handleDeleteExhibitor = async ()=>{
    try {
      const exhibitorDeletedResponse = await axiosPrivate.delete(EXHIBITOR_DELETE_URL+`/${deleteModal.exhibitor?.exhibitorId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(exhibitorDeletedResponse?.data.message);
      const afterDeleted = exhibitors.filter(exhibitor=> exhibitor.exhibitorId !== deleteModal.exhibitor?.exhibitorId);
      setChangeData(afterDeleted)
      setDeleteModal({exhibitor: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const handleChangeExhibitorPassword = async (formData)=>{
    const requestBody ={...formData, 
      key: passwordModal.exhibitor?.user.lastKey,
      userId: passwordModal.exhibitor?.user.userId}
    try {
      const exhibitorChangeResponse = await axiosPrivate.post(EXHIBITOR_CHANGE_PASSWORD_URL,requestBody,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      // TODO update changed exhibitor lastKey for changed passowrd
      const newLastKey = atob(exhibitorChangeResponse.data?.data);
      const otherExhibitors = exhibitors?.filter(e=> e.exhibitorId !== passwordModal.exhibitor.exhibitorId);
      const exhibitor = exhibitors?.find((e)=>e.exhibitorId===passwordModal.exhibitor.exhibitorId);
      setExhibitorsList(
        [...otherExhibitors, {...exhibitor, user: {...exhibitor.user, lastKey: newLastKey}}]
      )
      toast.success(exhibitorChangeResponse?.data.message);
      setPasswordModal({exhibitor: null, isShown: false})
    } catch (error) {
      if(error instanceof DOMException) {
        toast.error('Failed to decode or parse the Base64 string');
      } else {
        toast.error(error.response.data.message);
      }
    }
  }
  const queryExhibitors = exhibitors?.filter((data) =>
    Object.values(data)?.some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  )

  return (
    <><ToastContainer/>
    <div className="flex justify-between w-full">
        <div className='flex w-1/2'>
            <h2>{exhibitorInfo.exhibitorTitle}</h2>
            <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={exhibitorInfo.searchExhibitorTxt} />
        </div>
        <Link className='bg-primary-color text-white p-2 text-sm'
        to={"/add-exhibitor"} >
            {exhibitorInfo.addExhibitorTxt}
        </Link>
    </div>
    <div className="overflow-x-auto w-full scrollbar-custom">
      <div className="inline-block min-w-full my-10">
          <div className="overflow-hidden">
              <table className="min-w-full border text-center text-sm">
              <thead
                  className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                  <tr key={"head-1"}>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.username}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.company.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.firstName.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.fileType.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.job.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.standNumber.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.standSize.label.replace("*","")}</th>
                  {/* <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.country.label.replace("*","")}</th> */}
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.phone.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.email.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.membersCount.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.mediaCount.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.vipsCount.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.contractorsCountText}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.badgesCountText}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.filesCountText}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{exhibitorInfo.visasCountText}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                  </tr>
              </thead>
              <tbody className='border-b'>
                  {state.isLoading?<tr><td colSpan={15} className='p-10'>
                      <AppLoading height={'h-[10px]'}/>
                      </td></tr>
                  : !exhibitors
                  ? <tr><td colSpan={15} className='p-10'>{state?.data?.message??state?.error?.message}</td></tr>
                  : queryExhibitors?.length===0
                  ? <tr><td colSpan={15} className='p-10'>{exhibitorInfo.noExhibitors}</td></tr>
                  : queryExhibitors.map((exhibitor)=>{
                  return <tr key={exhibitor.exhibitorId} className='border-b'>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.user.username}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor?.company}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.firstName} {exhibitor.lastName}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.fileType}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor?.job}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.standNumber}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor?.standSize}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.phone}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.email}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.membersCount}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.mediaCount}</td>
                  <td className="whitespace-nowrap border-r p-4">{exhibitor.vipsCount}</td>
                  <td className="whitespace-nowrap border-r p-4">
                    <Link className='inline-block normal-link text-lg'
                      to={`/exhibitors/contractors/${exhibitor.exhibitorId}`}>
                          <MdGroups size={20} className='inline-block mx-1'/>{exhibitor.contractors.length}
                      </Link></td>
                  <td className="whitespace-nowrap border-r p-4">
                    <Link className='inline-block normal-link text-lg'
                      to={`/exhibitors/badges/${exhibitor.exhibitorId}`}
                      onClick={() => localStorage.setItem('exhibitorsState', JSON.stringify(exhibitors))}>
                          <IoIdCardSharp size={20} className='inline-block mx-1'/>{exhibitor.badges.length}
                      </Link></td>
                  <td className="whitespace-nowrap border-r p-4">
                    <Link className='inline-block normal-link text-lg'
                      to={`/exhibitors/tasks/${exhibitor.exhibitorId}`}>
                          <GiFiles size={20} className='inline-block mx-1'/>{exhibitor.tasks.length}
                      </Link></td>
                  <td className="whitespace-nowrap border-r p-4">
                    <Link className='inline-block normal-link text-lg'
                      to={`/exhibitors/visas/${exhibitor.exhibitorId}`}>
                          <BsFillPassportFill size={20} className='inline-block mx-1'/>{exhibitor.visas.length}
                      </Link></td>
                  <td className="whitespace-nowrap border-r p-4 flex justify-center">
                      <Link className='bg-green-300 inline-block mx-0.5'
                      to={"/exhibitors/edit"} state={{exhibitor}}>
                          <MdEdit size={17}/>
                      </Link>
                      <button className='bg-red-300 mx-0.5' onClick={()=> setDeleteModal({exhibitor, isShown: true})}>
                          <MdDelete size={17}/>
                      </button>
                      <button className='bg-green-300 mx-0.5' onClick={()=> setPasswordModal({exhibitor, isShown: true})}>
                          <MdLock size={17}/>
                      </button>
                  </td>
                  <ChangePasswordModal title={exhibitorInfo.changePasswordTitle}
                    showModal={passwordModal.isShown}
                    setShowModal={(value)=>setPasswordModal({exhibitor: null, isShown: value})}>
                      <ChangePasswordForm
                      onSubmit={handleChangeExhibitorPassword}
                      exhibitorId={exhibitor?.exhibitorId}/>
                  </ChangePasswordModal>
                  <ConfirmationModal
                      key={exhibitor?.exhibitorId}
                      title={exhibitorInfo.confirmDeleteTitle}
                      btnColor={"bg-danger"}
                      message={exhibitorInfo.confirmDeleteBody}
                      onAction={handleDeleteExhibitor}
                      showModal={deleteModal.isShown}
                      setShowModal={(value)=>setDeleteModal({exhibitor: null, isShown: value})} />
                  </tr>
                  })}
              </tbody>
              </table>
          </div>
      </div>
    </div>
    </>
  )
}

export default ExhibitorsList