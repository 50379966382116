import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as utils from './FormUtils/ExhibitorUtils';
import { ValidationError } from 'yup';

const ChangePasswordForm = ({onSubmit, exhibitorId}) => {
    const {t} = useTranslation();
    const [errors, setErrors] = useState();
    const exhibitorInfo = t("exhibitorInfo") 
    const handleChangePasswordValidation= async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        try {
        await utils.changePasswordValidationSchema(exhibitorInfo, t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        onSubmit(formData);
        setErrors(null)
        e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
        });
      } catch (error) {
        let allErrors = {};
        console.log(error instanceof ValidationError?"validation error...":error)
        error?.inner?.forEach((err)=>{
          allErrors[err.path]=err.message;
        })
        setErrors(allErrors)
      }
    }
  return (
    <form className='w-full' onSubmit={handleChangePasswordValidation} method='post'>
        <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
            {/* <div className="md:w-1/4">
                <label htmlFor="password" className="text-lg">{exhibitorInfo.password?.label}</label>
                <input type="password" name="password" id="password" className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={exhibitorInfo.password?.label} />
                {errors?.password&&<div className='text-red-600'>{errors?.password}</div>}
            </div> */}
            <div className="md:w-1/2">
                <label htmlFor={`newPassword_${exhibitorId}`} className="text-lg">{exhibitorInfo.newPassword?.label}</label>
                <input type="password" name="newPassword" id={`newPassword_${exhibitorId}`} className="sm:text-sm bg-slate-100 rounded-lg w-full p-2.5" placeholder={exhibitorInfo.newPassword?.label} />
                {errors?.newPassword&&<div className='text-red-600'>{errors?.newPassword}</div>}
            </div>
            <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{t("changePasswordBtn")}</button>
        </div>
    </form>
  )
}

export default ChangePasswordForm