import React, { useEffect, useRef, useState } from 'react'
import useCheckRouteAuthorization from '../../apis/useCheckRouteAuthorization';
import { useLocation, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { useTranslation } from 'react-i18next';
import * as utils from '../../utils/FormUtils/ContentUtils';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../../apis/axios';
const CONTENT_SAVE_INFO_URL = "/contents/save";

const AddOrEditEventContent = () => {
    useCheckRouteAuthorization();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const contentInfo = t("contentInfo");
    const content = location.state?.content;
    const screenId = location.state?.screenId;
    const route = location.pathname.split("/")[3];
  
    useEffect(()=>{
      if((!content&&route==="edit") || (!screenId&&route==="add")) navigate("/event-data")
    }, [navigate, content, route, screenId])

    const editorAr = useRef(null) 
    const editorEn = useRef(null) 
    // const editorEml = useRef(null) 
    const [contentDesc, setContentDescription] = useState(utils.initConstentDescription(content));
    const [errors, setErrors] = useState();
    
    const handleAddOrEditContent = async(e)=>{
      e.preventDefault();
      const data = new FormData(e.target);
      const formData = Object.fromEntries(data.entries());
      try {
        await utils.validationSchema(contentInfo, content, t("requiredMessage"))
        .validate({...formData, ...contentDesc}, {abortEarly: false});
        setErrors(null)
        const contentData = new FormData()
        utils.fillContentData(contentData, {...formData, ...contentDesc}, screenId, content?content.contentId:null);
        const contentRows = Object.fromEntries(contentData.entries());
        console.log(contentRows)
        const infoResponse = await axiosPrivate.post(CONTENT_SAVE_INFO_URL, contentData,
            {headers: {'Accept-Language': i18n.language}}
        );
        if(!content){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        }
        toast.success(infoResponse?.data.message);
        navigate(-1)
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        } else
        toast.error(error.response.data.message)
      }
    }
    const handleOnDescriptionBlur = (_)=>{
      const arDescription = editorAr.current.value;
      const enDescription = editorEn.current.value;
      // const emlDescription = editorEml.current.value;
      const onlyHtmlTagPattern = /^(\s*<\/?\w+(\s+\w+(\s*=\s*(?:"[^"]*"|'[^']*'|[^\s'">]+))?)*\s*\/?>\s*)*$/;
      let arText='';
      let enText='';
      if(!onlyHtmlTagPattern.test(arDescription)) arText = arDescription
      else arText = content?content?.arDescription:null;
      if(!onlyHtmlTagPattern.test(enDescription)) enText = enDescription
      else enText = content?content?.enDescription:null;
      setContentDescription((prev)=>{return {...prev, arDescription: arText, enDescription: enText}});
    }
  return (
    <div>
    <h4>{ `${content?contentInfo.editContentTxt:contentInfo.addContentTxt} ${i18n.language==="en"?content?.enTitle??'':content?.arTitle??''}` }</h4>
    <ToastContainer/>
    <form className='w-full' onSubmit={handleAddOrEditContent} method='post'>
      <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
          <div className="md:w-1/4">
            <label htmlFor="arTitle" className="text-lg">{contentInfo.arTitle?.label}</label>
            <input type="text" name="arTitle" id="arTitle" defaultValue={content?.arTitle??''} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={contentInfo.arTitle?.label} />
            {errors?.arTitle&&<div className='text-red-600'>{errors?.arTitle}</div>}
          </div>
          <div className="md:w-1/4">
            <label htmlFor="enTitle" className="text-lg">{contentInfo.enTitle?.label}</label>
            <input type="text" name="enTitle" id="enTitle" defaultValue={content?.enTitle} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={contentInfo.enTitle?.label} />
            {errors?.enTitle&&<div className='text-red-600'>{errors?.enTitle}</div>}
          </div>
          <div className="md:w-1/4">
            <label htmlFor="priority" className="text-lg">{contentInfo.priority?.label}</label>
            <input type="number" name="priority" id="priority" defaultValue={content?.priority} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={contentInfo.priority?.label} />
            {errors?.priority&&<div className='text-red-600'>{errors?.priority}</div>}
          </div>
      </div>
      <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
          <div className="md:w-1/4">
            <label htmlFor="cImage" className="text-lg">{contentInfo.cImage?.label}</label>
            <input type="file" name="img" id="cImage" className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={contentInfo.cImage?.label} required={route==="edit"?false:false} />
            {errors?.img&&<div className='text-red-600'>{errors?.img}</div>}
          </div>
          <div className="md:w-full my-6">
            <label htmlFor="arDesc" className="text-lg">{contentInfo.arDesc?.label} <small>add <code>class="table-bordered"</code> for &gt;table&gt; tag</small></label>
            <JoditEditor
              ref={editorAr}
              value={contentDesc.arDescription}
              onBlur={handleOnDescriptionBlur}
            />
            <div className='my-6' key={content?.id}
            dangerouslySetInnerHTML={{__html: contentDesc.arDescription===''?content?.arDescription:contentDesc.arDescription}}></div>
            {errors?.arDescription&&<div className='text-red-600'>{errors?.arDescription}</div>}
          </div>
          <div className="md:w-full my-6">
            <label htmlFor="enDesc" className="text-lg">{contentInfo.enDesc?.label} <small>add <code>class="table-bordered"</code> for &gt;table&gt; tag</small></label>
            <JoditEditor
              ref={editorEn}
              value={contentDesc.enDescription}
              onBlur={handleOnDescriptionBlur}
            />
            <div className='my-6' key={content?.id}
            dangerouslySetInnerHTML={{__html: contentDesc.enDescription===''?content?.enDescription:contentDesc.enDescription}}></div>
            {errors?.enDescription&&<div className='text-red-600'>{errors?.enDescription}</div>}
          </div>
          {/* <div className="md:w-full my-6">
            <label htmlFor="emailSignature" className="text-lg">{contentInfo.emailSignature?.label}</label>
            <JoditEditor
              ref={editorEml}
              value={contentDesc.emlDescription}
              onBlur={handleOnDescriptionBlur}
            />
            <div className='my-6' key={content?.id}
            dangerouslySetInnerHTML={{__html: contentDesc.emlDescription===''?content?.enDescription:contentDesc.emlDescription}}></div>
          </div> */}
          <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="add"?t("addBtn"):t("editBtn")}</button>
      </div>
    </form>
    </div>
  )
}

export default AddOrEditEventContent