import *as Yup from 'yup'

export const validationSchema =(eventTexts, events, requiredMessage, isEditing)=> {
    console.log(events)
    
    return Yup.object({
    arName: Yup.string().required(requiredMessage),
    enName: Yup.string().required(requiredMessage),
    clonedEventId: Yup.number().when([], {
    is: () => !isEditing,
    then: ()=> Yup.number()
    .oneOf(events.map(e=>e.eventId), eventTexts?.clonedEvent.invalidType)
    .required(requiredMessage),
    otherwise: ()=> Yup.number().notRequired(),
})})}