import React, { useState, useEffect } from 'react'
import {MdDownload } from 'react-icons/md'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useExhibitorsList } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
import { useParams } from 'react-router-dom';
const EXHIBITOR_CONTRACTOR_STATUS_URL = '/contractors/change/status'

const ExhibitorContractors = () => {
  useCheckRouteAuthorization();
  const {exhibitors, setExhibitorsList} = useExhibitorsList()
  const {t, i18n} = useTranslation();
  const contractorInfo = t("contractorInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const {exhibitorId} = useParams();
  const exhibitor = exhibitors?.find((e)=>e.exhibitorId===Number(exhibitorId));
  const exhibitorContractors = exhibitor?.contractors;

  // Load exhibitors from localStorage if available
  useEffect(() => {
    const storedExhibitors = localStorage.getItem('exhibitorsState');
    if (storedExhibitors && !exhibitors) {
      setExhibitorsList(JSON.parse(storedExhibitors));
    }
  }, [setExhibitorsList, exhibitors]);

  const handleChangeContractorStatus = async (contractorId, status)=>{
    try {
      const statusChangedResponse = await axiosPrivate.post(EXHIBITOR_CONTRACTOR_STATUS_URL, {id: contractorId, status},
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );

      toast.success(statusChangedResponse?.data.message);
      const otherContractors = exhibitorContractors.filter(contractor=> contractor.contractorId !== contractorId);
      const otherExhibitors = exhibitors.filter(e=> e.exhibitorId !== Number(exhibitorId));
      const oldContractor = exhibitorContractors.find(contractor=> contractor.contractorId === contractorId);
      setExhibitorsList(
        [...otherExhibitors, {...exhibitor, contractors: [...otherContractors, {...oldContractor, status: statusChangedResponse.data?.data}]}]
    )
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  }
    const contractors = exhibitorContractors?.filter((data) =>
        Object.values(data).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )

  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{contractorInfo.contractorsTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={contractorInfo.searchContractorTxt} />
            </div>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.companyName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.companyPhone.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.personName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.personPhone.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.crNumber.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.companyAddress.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.personEmail.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.crImgCopy.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{contractorInfo.service.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {
                        contractors?.length===0
                        ? <tr><td colSpan={11} className='p-10'>{contractorInfo.noContractors}</td></tr>
                        : contractors?.map((contractor)=>{
                        return <tr key={contractor.contractorId} className='border-b'>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.companyName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.companyPhone}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.personName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.personPhone}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.crNumber??'_'}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.companyAddress??'_'}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.personEmail??'_'}</td>
                        <td className="whitespace-nowrap border-r p-4">
                          {contractor.crImgCopy ?
                            <><MdDownload className='inline-block primary-color mx-1'/>
                            <a href={`${BASE_URL}/files/get/file/${contractor.crImgCopy}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a></>
                          : "_"}
                        </td>
                        <td className="whitespace-nowrap text-start border-r p-4">{contractor.service??'_'}</td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-end">
                            {contractor.status==="PENDING" &&
                            <button className='bg-green-300 mx-0.5' onClick={()=> handleChangeContractorStatus(contractor.contractorId, "APPROVED")}>
                                موافقة
                            </button>}
                            {contractor.status==="PENDING" &&
                            <button className='bg-red-300 mx-0.5' onClick={()=> handleChangeContractorStatus(contractor.contractorId, "REJECTED")}>
                                رفض
                            </button>}
                            {contractor.status!=="PENDING" &&
                            <button className='bg-yellow-300 mx-0.5' onClick={()=> handleChangeContractorStatus(contractor.contractorId, "PENDING")}>
                                إعادة تعيين
                            </button>}
                        </td>
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExhibitorContractors