import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import * as utils from '../utils/FormUtils/ExhibitorUtils';
import { TEAlert } from "tw-elements-react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../apis/axios';
import { useAuth } from '../hooks/appHooks';

const EXHIBITOR_SAVE_INFO_URL = "/exhibitors/save"

const AddOrEditExhibitors = () => {
  useCheckRouteAuthorization();
  const { auth } = useAuth();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const exhibitorInfo = t("exhibitorInfo");
  const exhibitor = location.state?.exhibitor;
  const route = location.pathname.split("/")[2];
  useEffect(()=>{
    if((!exhibitor&&route==="edit")) navigate("/exhibitors")
  }, [navigate, exhibitor, route])

  const [errors, setErrors] = useState();
  const [firstPasswordMessage, setFirstPasswordMessage] = useState(null);
  
    const handleAddOrEditExhibitor = async(e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());
        console.log("formData.standSize", formData)
        try {
        await utils.validationSchema(exhibitorInfo, formData.standSize, t("requiredMessage"))
        .validate(formData, {abortEarly: false});
        setErrors(null)
        const requestBody = {
          ...formData,
          exhibitorId: exhibitor?exhibitor?.exhibitorId:null,
          eventId: exhibitor?exhibitor?.user.event.eventId:auth.event.eventId
        }
        const infoResponse = await axiosPrivate.post(EXHIBITOR_SAVE_INFO_URL, requestBody,
            {headers: {'Accept-Language': i18n.language}}
        );
        if(!exhibitor){ 
          e.target.querySelectorAll('input').forEach(input => {
            input.value = '';
          });
        }
        toast.success(`${infoResponse?.data.message} ${exhibitorInfo.firstPassword} ${infoResponse?.data.firstPassword}`);
        navigate(-1)
        setFirstPasswordMessage(infoResponse?.data.firstPassword)
      } catch (error) {
        if(error instanceof ValidationError){
          let allErrors = {};
          console.error("validation error...")
          error?.inner?.forEach((err)=>{
            allErrors[err.path]=err.message;
          })
          setErrors(allErrors)
        }
        toast.error(error.response?.data.message)
      }
    }

  return (
    <div>
      <ToastContainer/>
        <h2>{exhibitor?exhibitorInfo.editExhibitorTxt:exhibitorInfo.addExhibitorTxt} {exhibitor&&exhibitor?.user.username}</h2>
        {firstPasswordMessage&&<TEAlert
          dismiss
          staticAlert
          open={true}
          onClosed={()=>setFirstPasswordMessage(null)}
          color="bg-info-50 text-info-700"
          className='inline'
        >
          <span className="ml-1 capitalize mx-1">
            {exhibitorInfo.firstPassword}
          </span>
          <strong>{firstPasswordMessage}</strong>
        </TEAlert>}
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditExhibitor} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="firstName" className="text-lg">{exhibitorInfo.firstName?.label}</label>
                      <input type="text" name="firstName" id="firstName" defaultValue={exhibitor?.firstName} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.firstName?.label} />
                      {errors?.firstName&&<div className='text-red-600'>{errors?.firstName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="lastName" className="text-lg">{exhibitorInfo.lastName?.label}</label>
                      <input type="text" name="lastName" id="lastName" defaultValue={exhibitor?.lastName} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.lastName?.label} />
                      {errors?.lastName&&<div className='text-red-600'>{errors?.lastName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="job" className="text-lg">{exhibitorInfo.job?.label}</label>
                      <input type="text" name="job" id="job" defaultValue={exhibitor?.job} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.job?.label} />
                      {errors?.job&&<div className='text-red-600'>{errors?.job}</div>}
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="company" className="text-lg">{exhibitorInfo.company?.label}</label>
                      <input type="text" name="company" id="company" defaultValue={exhibitor?.company} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.company?.label} />
                      {errors?.company&&<div className='text-red-600'>{errors?.company}</div>}
                    </div>
                    {/* <div className="md:w-1/4">
                      <label htmlFor="country" className="text-lg">{exhibitorInfo.country?.label}</label>
                      <input type="text" name="country" id="country" defaultValue={exhibitor?.country} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.country?.label} />
                      {errors?.country&&<div className='text-red-600'>{errors?.country}</div>}
                    </div> */}
                    <div className="md:w-1/4">
                      <label htmlFor="city" className="text-lg">{exhibitorInfo.city?.label}</label>
                      <input type="text" name="city" id="city" defaultValue={exhibitor?.city} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.city?.label} />
                      {errors?.city&&<div className='text-red-600'>{errors?.city}</div>}
                    </div>
                
                    <div className="md:w-1/4">
                      <label htmlFor="phone" className="text-lg">{exhibitorInfo.phone?.label}</label>
                      <input type="text" name="phone" id="phone" defaultValue={exhibitor?.phone} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.phone?.label} />
                      {errors?.phone&&<div className='text-red-600'>{errors?.phone}</div>}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="email" className="text-lg">{exhibitorInfo.email?.label}</label>
                      <input type="text" name="email" id="email" defaultValue={exhibitor?.email} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.email?.label} />
                      {errors?.email&&<div className='text-red-600'>{errors?.email}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="membersCount" className="text-lg">{exhibitorInfo.membersCount?.label}</label>
                      <input type="number" name="membersCount" id="membersCount" defaultValue={exhibitor?.membersCount} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.membersCount?.label} />
                      {errors?.membersCount&&<div className='text-red-600'>{errors?.membersCount}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="mediaCount" className="text-lg">{exhibitorInfo.mediaCount?.label}</label>
                      <input type="number" name="mediaCount" id="mediaCount" defaultValue={exhibitor?.mediaCount} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.mediaCount?.label} />
                      {errors?.mediaCount&&<div className='text-red-600'>{errors?.mediaCount}</div>}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="vipsCount" className="text-lg">{exhibitorInfo.vipsCount?.label}</label>
                      <input type="number" name="vipsCount" id="vipsCount" defaultValue={exhibitor?.vipsCount} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.vipsCount?.label} />
                      {errors?.vipsCount&&<div className='text-red-600'>{errors?.vipsCount}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="standNumber" className="text-lg">{exhibitorInfo.standNumber?.label}</label>
                      <input type="text" name="standNumber" id="standNumber" defaultValue={exhibitor?.standNumber} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.standNumber?.label} />
                      {errors?.standNumber&&<div className='text-red-600'>{errors?.standNumber}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="standSize" className="text-lg">{exhibitorInfo.standSize?.label}</label>
                      <input type="number" name="standSize" id="standSize" defaultValue={exhibitor?.standSize} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.standSize?.label} />
                      {errors?.standSize&&<div className='text-red-600'>{errors?.standSize}</div>}
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="exhibitorType" className="text-lg">{exhibitorInfo.exhibitorType?.label}</label>
                      <select name="exhibitorType" id="exhibitorType" defaultValue={exhibitor?.exhibitorType} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.exhibitorType?.label}>
                        <option value="EXHIBITOR">EXHIBITOR / عارض</option>  
                        <option value="SPONSOR">SPONSOR / راعي</option>  
                      </select>
                      {errors?.exhibitorType&&<div className='text-red-600'>{errors?.exhibitorType}</div>}
                    </div>
                    <div className="md:w-1/4">
                    {/* Don't forget to add/remove items from validation list in ExhibitorUtils  */}
                      <label htmlFor="fileType" className="text-lg">{exhibitorInfo.fileType?.label}</label>
                      <select name="fileType" id="fileType" defaultValue={exhibitor?.fileType} className="sm:text-sm bg-slate-100 w-full p-2.5" placeholder={exhibitorInfo.fileType?.label}>
                        <option value="SPACE_ONLY">SPACE_ONLY / مساحة فارغة</option>
                        <option value="STANDARD_BOOTH">STANDARD_BOOTH / تجهيزات موحدة</option>
                        <option value="OFFICIAL">OFFICIAL SPONSER / الراعي الرسمي</option>
                        <option value="DIAMOND">DIAMOND SPONSER / الراعي الماسي</option>
                        <option value="PLATINUM">PLATINUM SPONSER / الراعي البلاتيني</option>
                        <option value="GOLDEN">GOLDEN SPONSER / الراعي الذهبي</option>
                        <option value="SILVER">SILVER SPONSER / الراعي الفضي</option>
                        <option value="CO">CO_SPONSER / الراعي المشارك</option>
                      </select>
                      {errors?.fileType&&<div className='text-red-600'>{errors?.fileType}</div>}
                    </div>
                    <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{route==="edit"?t("editBtn"):t("addBtn")}</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddOrEditExhibitors