
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import RequiredAuth from './layouts/RequiredAuth';
import { AuthProvider } from './provider/AuthProvider';
import Login from './pages/Login';
import Unauthorized from './pages/Unauthorized';
import EventData from './pages/EventData';
import ExhibitorsList from './pages/ExhibitorsList';
import AddOrEditExhibitors from './pages/AddOrEditExhibitors';
import ExhibitorFiles from './pages/ExhibitorFiles';
import AddOrEditEventContent from './pages/EventContent/AddOrEditEventContent';
import AddOrEditExhibitorFiles from './pages/ExhibitorFiles/AddOrEditExhibitorFiles';
import ExhibitorTasks from './pages/ExhibitorTasks';
import ExhibitorVisas from './pages/ExhibitorVisas';
import ExhibitorContractors from './pages/ExhibitorContractors';
import ExhibitorBadges from './pages/ExhibitorBadges';
import ExhibitorAgreements from './pages/ExhibitorAgreements';
import EventDataContents from './pages/EventDataContents';
import NotFound from './pages/NotFound';
import AddOrEditEvent from './pages/AddOrEditEvent';
import EventsList from './pages/EventsList';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path='*' element={<NotFound />}/>
      <Route path="/super" element={<RequiredAuth routeRole={"MANAGER"}/>}>
        <Route index element={<AddOrEditEvent />}/>
        <Route path='events-archive' element={<EventsList />}/>
        <Route path='events/edit' element={<AddOrEditEvent />}/>
        <Route path='events' element={<AddOrEditEvent />}/>
      </Route>
      <Route path="/" element={<RequiredAuth routeRole={"SUPERADMIN"}/>}>
        <Route index element={<ExhibitorsList />}/>
        <Route path='exhibitors' element={<ExhibitorsList />}/>
        <Route path='exhibitors/edit' element={<AddOrEditExhibitors />}/>
        <Route path='exhibitors/tasks/:exhibitorId' element={<ExhibitorTasks />}/>
        <Route path='exhibitors/visas/:exhibitorId' element={<ExhibitorVisas />}/>
        <Route path='exhibitors/badges/:exhibitorId' element={<ExhibitorBadges />}/>
        <Route path='exhibitors/contractors/:exhibitorId' element={<ExhibitorContractors />}/>
        <Route path='agreements' element={<ExhibitorAgreements />}/>
        <Route path='add-exhibitor' element={<AddOrEditExhibitors />}/>
        <Route path='exhibitor-files' element={<ExhibitorFiles />}/>
        <Route path='exhibitor-files/add' element={<AddOrEditExhibitorFiles />}/>
        <Route path='exhibitor-files/edit' element={<AddOrEditExhibitorFiles />}/>
        <Route path='event-data' element={<EventData />}/>
        <Route path='event-data/contents/:screenId' element={<EventDataContents />}/>
        <Route path='event-data/contents/edit' element={<AddOrEditEventContent />}/>
        <Route path='event-data/contents/add' element={<AddOrEditEventContent />}/>
      </Route>
    </Route>
  )
)

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router}/>
    </AuthProvider>
  );
}

export default App;
