import React, { useState } from 'react'
import {MdArchive, MdDelete, MdEdit, MdUnarchive} from 'react-icons/md'
import { Link } from 'react-router-dom'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization'
import { useAuth } from '../hooks/appHooks'
import AppLoading from '../utils/AppLoading'
import useAxiosFetchApi from '../hooks/useFetch'
import ConfirmationModal from './modals/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import { axiosPrivate } from '../apis/axios'
const EVENTS_LIST_URL = "/events/archive"
const EVENT_TOGGLE_URL = "/events/toggle/status"
const EVENT_DELETE_URL = "/events/delete"

const EventsList = () => {
  useCheckRouteAuthorization()
  const {auth} = useAuth()
  const {t, i18n} = useTranslation();
  const eventInfo = t("eventInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({event: null, isShown: false});
  const [state, _, setChangeData] = useAxiosFetchApi(EVENTS_LIST_URL, {}, auth.token);
  const events = state.data?.list;
  const queryEvents = events?.filter((data) =>
    Object.values(data).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  )
  const handleDeleteEvent = async ()=>{
    try {
      const eventDeletedResponse = await axiosPrivate.delete(EVENT_DELETE_URL+`/${deleteModal.event?.eventId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(eventDeletedResponse?.data.message);
      const afterDeleted = events.filter(event=> event?.eventId !== deleteModal.event?.eventId);
      setChangeData(afterDeleted)
      setDeleteModal({event: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  const handleArchiveEvent = async (eventId)=>{
    try {
      const eventArchivedResponse = await axiosPrivate.get(EVENT_TOGGLE_URL+`/${eventId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(eventArchivedResponse?.data.message);
      const afterArchived = events.filter(event=> event?.eventId !== eventId);
      setChangeData(afterArchived)
      // setDeleteModal({event: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <><ToastContainer/>
    <div className="flex justify-between w-full">
        <div className='flex w-1/2'>
            <h2>{eventInfo.eventTitle}</h2>
            <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={eventInfo.searchEventTxt} />
        </div>
        <Link className='bg-primary-color text-white p-2 text-sm'
        to={"/super/events"} >
            {eventInfo.addEventTxt}
        </Link>
    </div>
    <div className="overflow-x-auto w-full scrollbar-custom">
      <div className="inline-block min-w-full my-10">
          <div className="overflow-hidden">
              <table className="min-w-full border text-center text-sm">
              <thead
                  className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                  <tr key={"head-1"}>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">#</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{eventInfo.arName.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{eventInfo.enName.label.replace("*","")}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{eventInfo.exhibitorsCountText}</th>
                  <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                  </tr>
              </thead>
              <tbody className='border-b'>
                  {state.isLoading?<tr><td colSpan={15} className='p-10'>
                      <AppLoading height={'h-[10px]'}/>
                      </td></tr>
                  : !events
                  ? <tr><td colSpan={15} className='p-10'>{state?.data?.message??state?.error?.message}</td></tr>
                  : queryEvents?.length===0
                  ? <tr><td colSpan={15} className='p-10'>{eventInfo.noEvents}</td></tr>
                  : queryEvents.map((event)=>{
                  return <tr key={event.eventId} className='border-b'>
                  <td className="whitespace-nowrap border-r p-4">{event.eventId}</td>
                  <td className="whitespace-nowrap border-r p-4">{event.arName}</td>
                  <td className="whitespace-nowrap border-r p-4">{event.enName}</td>
                  <td className="whitespace-nowrap border-r p-4">{event.exhibitors?.length??"_"}</td>
                  <td className="whitespace-nowrap border-r p-4 flex justify-center">
                      <Link className='bg-green-300 inline-block mx-0.5'
                      to={"/super/events/edit"} state={{event}}>
                          <MdEdit size={17}/>
                      </Link>
                      <button className='bg-gray-300 mx-0.5' onClick={()=> handleArchiveEvent(event.eventId)}>
                          <MdUnarchive size={17}/>
                      </button>
                      <button className='bg-red-300 mx-0.5' onClick={()=> setDeleteModal({event, isShown: true})}>
                          <MdDelete size={17}/>
                      </button>
                  </td>
                  <ConfirmationModal
                      key={event?.eventId}
                      title={eventInfo.confirmDeleteTitle}
                      btnColor={"bg-danger"}
                      message={eventInfo.confirmDeleteBody}
                      onAction={handleDeleteEvent}
                      showModal={deleteModal.isShown}
                      setShowModal={(value)=> setDeleteModal({event: null, isShown: value})} />
                  </tr>
                  })}
              </tbody>
              </table>
          </div>
      </div>
    </div>
    </>
  )
}

export default EventsList