import React, { useState, useEffect } from 'react'
import {MdDownload } from 'react-icons/md'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useExhibitorsList } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
import { useParams } from 'react-router-dom';
const EXHIBITOR_VISA_STATUS_URL = '/visas/change/status'

const ExhibitorVisas = () => {
  useCheckRouteAuthorization();
  const {exhibitors, setExhibitorsList} = useExhibitorsList()
  const {t, i18n} = useTranslation();
  const visaInfo = t("visaInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const {exhibitorId} = useParams();
  const exhibitor = exhibitors?.find((e)=>e.exhibitorId===Number(exhibitorId));
  const exhibitorVisas = exhibitor?.visas;

  // Load exhibitors from localStorage if available
  useEffect(() => {
    const storedExhibitors = localStorage.getItem('exhibitorsState');
    if (storedExhibitors && !exhibitors) {
      setExhibitorsList(JSON.parse(storedExhibitors));
    }
  }, [setExhibitorsList, exhibitors]);

  const handleChangeVisaStatus = async (visaId, status)=>{
    try {
      const statusChangedResponse = await axiosPrivate.post(EXHIBITOR_VISA_STATUS_URL, {id: visaId, status},
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );

      toast.success(statusChangedResponse?.data.message);
      const otherVisas = exhibitorVisas.filter(visa=> visa.visaId !== visaId);
      const otherExhibitors = exhibitors.filter(e=> e.exhibitorId !== Number(exhibitorId));
      const oldVisa = exhibitorVisas.find(visa=> visa.visaId === visaId);
      setExhibitorsList(
        [...otherExhibitors, {...exhibitor, visas: [...otherVisas, {...oldVisa, status: statusChangedResponse.data?.data}]}]
    )
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  }
    const visas = exhibitorVisas?.filter((data) =>
        Object.values(data).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )
  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{visaInfo.visasTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={visaInfo.searchVisaTxt} />
            </div>
        </div>
        <div className="overflow-x-auto w-full scrollbar-custom">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.fullName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.nationality.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.gender.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.age.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.placeOfBirth.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.dateOfBirth.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportType.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportNumber.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportExpire.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportIssuePlace.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.passportPhoto.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.companyName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.companyActivity.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.jobTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.religion.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{visaInfo.travelSource.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {
                        visas?.length===0
                        ? <tr><td colSpan={11} className='p-10'>{visaInfo.noVisas}</td></tr>
                        : visas?.map((visa)=>{
                        return <tr key={visa.visaId} className='border-b'>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.fullName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.nationality}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.gender}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.age}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.placeOfBirth}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.dateOfBirth}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.passportType}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.passportNumber}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.passportExpire}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.passportIssuePlace}</td>
                        <td className="whitespace-nowrap border-r p-4">
                            <MdDownload className='inline-block primary-color mx-1'/>
                            <a href={`${BASE_URL}/files/get/file/${visa.passportPhoto}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a>
                        </td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.companyName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.companyActivity}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.jobTitle}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.religion}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{visa.travelSource}</td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-end">
                            {visa.status==="PENDING" &&
                            <button className='bg-green-300 mx-0.5' onClick={()=> handleChangeVisaStatus(visa.visaId, "APPROVED")}>
                                موافقة
                            </button>}
                            {visa.status==="PENDING" &&
                            <button className='bg-red-300 mx-0.5' onClick={()=> handleChangeVisaStatus(visa.visaId, "REJECTED")}>
                                رفض
                            </button>}
                            {visa.status!=="PENDING" &&
                            <button className='bg-yellow-300 mx-0.5' onClick={()=> handleChangeVisaStatus(visa.visaId, "PENDING")}>
                                إعادة تعيين
                            </button>}
                        </td>
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExhibitorVisas