
import { React, useEffect, useState } from 'react';

import { Collapse, Ripple, initTE } from "tw-elements";
import useAxiosFetchApi from '../hooks/useFetch';
import AppLoading from '../utils/AppLoading';
import { useAuth } from '../hooks/appHooks';
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { BsLayoutWtf } from "react-icons/bs";

initTE({ Collapse, Ripple });
const MAIN_SCREENS_URL = '/screens/role/USER';

const EventData = () => {

  useCheckRouteAuthorization();
  const { auth } = useAuth();
  const{t, i18n} = useTranslation();
  const [state] = useAxiosFetchApi(MAIN_SCREENS_URL, {}, auth.token);
  const screenInfo = t("screenInfo");
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(()=>{
    initTE({ Collapse, Ripple });
  })
  var allScreens=state.data?.list;

  const screens = allScreens?.filter((data) =>
    Object.values(data).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  )
  
  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{screenInfo.screensTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={screenInfo.searchScreenTxt} />
            </div>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{i18n.language==='ar' 
                        ? screenInfo.arTitle.label.replace("*","")
                        : screenInfo.enTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {state.isLoading?<tr><td colSpan={7} className='p-10'>
                            <AppLoading/>
                            </td></tr>
                        : !screens
                        ? <tr><td colSpan={11} className='p-10'>{state?.data?.message??state?.error?.message}</td></tr>
                        : screens?.length===0
                        ? <tr><td colSpan={11} className='p-10'>{screenInfo.noScreens}</td></tr>
                        : screens?.map((screen)=>{
                        return <tr key={screen.screenId} className='border-b'>
                        <td className="whitespace-nowrap text-start border-r p-4">{i18n.language==='ar' ? screen.arTitle : screen.enTitle}</td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-center">
                            <Link className='bg-green-300 inline-block mx-0.5'
                            to={`/event-data/contents/${screen.screenId}`}>
                                <BsLayoutWtf size={20} className='inline-block mx-1'/>{screenInfo.screensTitle}
                            </Link>
                        </td>
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EventData