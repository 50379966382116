import React, { useState, useEffect } from 'react'
import {MdDownload } from 'react-icons/md'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useExhibitorsList } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
import { useParams } from 'react-router-dom';
const EXHIBITOR_TASK_STATUS_URL = '/exhibitorTasks/change/status'

const ExhibitorTasks = () => {
  useCheckRouteAuthorization();
  const {exhibitors, setExhibitorsList} = useExhibitorsList()
  const {t, i18n} = useTranslation();
  const fileInfo = t("fileInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const {exhibitorId} = useParams();
  const exhibitor = exhibitors?.find((e)=>e.exhibitorId===Number(exhibitorId));
  const exhibitorTasks = exhibitor?.tasks;

  // Load exhibitors from localStorage if available
  useEffect(() => {
    const storedExhibitors = localStorage.getItem('exhibitorsState');
    if (storedExhibitors && !exhibitors) {
      setExhibitorsList(JSON.parse(storedExhibitors));
    }
  }, [setExhibitorsList, exhibitors]);

  const handleChangeTaskStatus = async (taskId, status)=>{
    try {
      const statusChangedResponse = await axiosPrivate.post(EXHIBITOR_TASK_STATUS_URL, {id: taskId, status},
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );

      toast.success(statusChangedResponse?.data.message);
      const otherTasks = exhibitorTasks.filter(task=> task.taskId !== taskId);
      const otherExhibitors = exhibitors.filter(e=> e.exhibitorId !== Number(exhibitorId));
      const oldTask = exhibitorTasks.find(task=> task.taskId === taskId);
      const newTaskList = [...otherTasks, {...oldTask, status: statusChangedResponse.data?.data}];
      setExhibitorsList(
        [...otherExhibitors, {...exhibitor, tasks: status==="PENDING" ? otherTasks : newTaskList}]
    )
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  }

    const tasks = exhibitorTasks?.filter((data) =>
        Object.values(data.exhibitorFile).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )
  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{fileInfo.exhibitorFileTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={fileInfo.searchFileTxt} />
            </div>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{fileInfo.arTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{fileInfo.enTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{fileInfo.taskFiles}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{i18n.language==="ar"?"النوع":"Type"}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {
                        tasks?.length===0
                        ? <tr><td colSpan={9} className='p-10'>{fileInfo.noTaskFiles}</td></tr>
                        : tasks?.map((task)=>{
                        return <tr key={task.exhibitorFile.fileId} className='border-b'>
                        <td className="whitespace-nowrap text-start border-r p-4">{task.exhibitorFile.arTitle}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{task.exhibitorFile.enTitle}</td>
                        <td className="whitespace-nowrap border-r p-4">
                            <MdDownload className='inline-block primary-color mx-1'/>
                            <a href={`${BASE_URL}/files/get/file/${task.file}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a>
                        </td>
                        <td className="whitespace-nowrap border-r py-4">
                            {task.exhibitorFile.optional?
                            i18n.language==="ar"?"إختياري":"optional":
                            i18n.language==="ar"?"إجباري":"mandatory"}</td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-end">

                            {task.status==="PENDING" &&
                            <button className='bg-green-300 mx-0.5' onClick={()=> handleChangeTaskStatus(task.taskId, "APPROVED")}>
                                موافقة
                            </button>}
                            {task.status==="PENDING" &&
                            <button className='bg-red-300 mx-0.5' onClick={()=> handleChangeTaskStatus(task.taskId, "REJECTED")}>
                                رفض
                            </button>}
                            <button className='bg-yellow-300 mx-0.5' onClick={()=> handleChangeTaskStatus(task.taskId, "PENDING")}>
                                إعادة تعيين
                            </button>
                        </td>
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExhibitorTasks