import *as Yup from 'yup'

export const validationSchema =(exhibitorTexts, standSize, requiredMessage)=> {
    console.log("standSize "+ typeof standSize)
    return Yup.object({
    firstName: Yup.string().required(requiredMessage),
    lastName: Yup.string().required(requiredMessage),
    job: Yup.string().notRequired(),
    company: Yup.string().notRequired(),
    // country: Yup.string().required(requiredMessage),
    city: Yup.string().notRequired(),
    phone: Yup.string().matches(/^(009665|9665|05)\d{8}$/,exhibitorTexts?.phone.phoneFormat).required(requiredMessage),
    email: Yup.string().email(exhibitorTexts?.email.emailFormat).required(requiredMessage),
    membersCount: Yup.number().min(0, exhibitorTexts?.membersCount.onlyNumber)
    .typeError(exhibitorTexts?.membersCount.onlyNumber).required(requiredMessage),
    mediaCount: Yup.number().min(0, exhibitorTexts?.mediaCount.onlyNumber)
    .typeError(exhibitorTexts?.mediaCount.onlyNumber).required(requiredMessage),
    vipsCount: Yup.number().min(0, exhibitorTexts?.vipsCount.onlyNumber)
    .typeError(exhibitorTexts?.vipsCount.onlyNumber).required(requiredMessage),
    standNumber: Yup.string().required(requiredMessage),
    standSize: Yup.number().min(0, exhibitorTexts?.standSize.onlyNumber)
    .typeError(exhibitorTexts?.standSize.onlyNumber).required(requiredMessage),
    exhibitorType: Yup.string()
    .oneOf(["EXHIBITOR", "SPONSOR"], exhibitorTexts?.exhibitorType.invalidType)
    .required(requiredMessage),
    fileType: Yup.string()
    .oneOf(["SPACE_ONLY", "STANDARD_BOOTH", "OFFICIAL", "DIAMOND", "PLATINUM", "GOLDEN", "SILVER", "CO"], exhibitorTexts?.fileType.invalidType)
    .required(requiredMessage),
})}

export const changePasswordValidationSchema =(exhibitorTexts, requiredMessage)=> {return Yup.object({
    // password: Yup.string()
    // .min(6)
    // .required(requiredMessage),
    newPassword: Yup.string()
    .min(6)
    .required(requiredMessage),
})}