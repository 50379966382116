import { createContext, useState } from "react";

const ExhibitorsListContext = createContext({})

export const ExhibitorsListProvider = ({children}) => {
    
    const [exhibitors, setExhibitorsList] = useState()

    return (
        <ExhibitorsListContext.Provider value={{ exhibitors, setExhibitorsList }}>
            {children}
        </ExhibitorsListContext.Provider>
    )
}

export default ExhibitorsListContext