import React, { useEffect } from 'react'
import { NavLink, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import logo from '../img/resta-logo.png';
import {useAuth} from '../hooks/appHooks';
import useAxiosFetchApi from '../hooks/useFetch';
import AppLoading from '../utils/AppLoading';
import LanguageSelector from '../utils/LanguageSelector';
import {IoArrowBack, IoArrowForward, IoLogOutOutline} from "react-icons/io5"
import axios from '../apis/axios';
import { ExhibitorsListProvider } from '../provider/ExhibitorsListProvider';
import { useTranslation } from 'react-i18next';
const MAIN_SCREENS_URL = '/screens/role';
const USER_PROFILE_URL = "/auth/profile";
const LOGOUT_URL = "/auth/logout";

const RequiredAuth = ({routeRole}) => {
    const { auth, setAuth } = useAuth();
    const sessionToken = auth.token;
    const [state] = useAxiosFetchApi(USER_PROFILE_URL, {}, sessionToken);
    const [screenState] = useAxiosFetchApi(`${MAIN_SCREENS_URL}/${routeRole}`, {}, sessionToken);
    const location = useLocation();
    const navigate = useNavigate();
    const{t, i18n} = useTranslation();
    const tMain = t("main")
    const userProfile = state.data?.data;
    const screens = screenState.data?.list;

    useEffect(() => {
        setAuth((prev)=> {return {...prev, role: userProfile?.role, event: userProfile?.event}});
    }, [setAuth, userProfile])
    
    
    const handleUserLogout = async (e)=>{
        e.preventDefault();
        const headers= {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${sessionToken}`,
            "Access-Control-Allow-Origin":"*"
        };
        try {
            const response = await axios.get(LOGOUT_URL,{headers});
            console.log(JSON.stringify(response?.data));
            setAuth({});
            localStorage.removeItem("ecms_token")
            localStorage.removeItem("refresh_token")
            navigate("/login", { replace: true })
        } catch (error) {
            
        }
    }
    // console.log(JSON.stringify(userProfile))
    console.log(routeRole)
    console.log(userProfile?.role)
    return (
        state.isLoading || screenState.isLoading
        ? <AppLoading/>
        : userProfile && userProfile.username
        ? userProfile.role===routeRole
        ? <div className='flex flex-col cairo-font font-normal'>
            <header className='h-[21%] border-b border-dashed border-primary-color'>
                <div className='flex shadow-2 fixed w-full bg-white'>
                    <div className='w-20 py-2 bg-primary-color'>
                        <img src={logo} width={70} alt='logo' />
                    </div>
                    <div className='flex justify-end items-center w-full'>
                        <p className='font-semibold mx-5'><span className='font-normal'>{userProfile.event?tMain.exhibitTxt:""} {i18n.language==="ar"?userProfile.event?.arName:userProfile.event?.enName}</span></p>
                        <p className='font-semibold mx-5'><span className='font-normal'>{userProfile.firstname} {userProfile.lastname}</span></p>
                        <LanguageSelector/>
                        <button className='flex mx-5' onClick={handleUserLogout}>
                            {tMain.logoutNavBtn}
                            <IoLogOutOutline className='ms-3 size-6'/>
                        </button>
                        <button className='flex mx-2 bg-white' onClick={()=>navigate(-1)}>
                            {"رجوع"}
                            {i18n.language==="ar"
                            ? <IoArrowBack className='ms-3 mt-1 size-6'/>
                            : <IoArrowForward className='ms-3 mt-1 size-6'/>}
                        </button>
                    </div>
                </div>
                {!screens?"Loading...":<nav className={`flex justify-center scrollbar-custom mt-24 mb-3 mx-3 py-3 text-sm`}>
                    {screens.slice(0, screens.length).map((navigator) =>
                        <NavLink className="w-2/12 whitespace-nowrap text-center font-bold" key={navigator.screenId} to={navigator.route}>{i18n.language==="en"?navigator.enTitle:navigator.arTitle}</NavLink>)
                    }
                </nav>}
            </header>
            <main className='w-full container mx-auto my-8'>
                <ExhibitorsListProvider>
                    <Outlet context={screens}/>
                </ExhibitorsListProvider>
            </main>
            {/* <footer className='self-stretch h-[200px] w-full bg-slate-800 mx-auto mt-6'>Footer</footer> */}
        </div>
        : userProfile.role==="MANAGER"
        ? <Navigate to="/super/events" state={{ from: location }} replace />
        : <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequiredAuth