import React, { useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import * as utils from '../utils/FormUtils/EventUtils';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { axiosPrivate } from '../apis/axios';
import useAxiosFetchApi from '../hooks/useFetch';
import { MdArchive, MdEdit } from 'react-icons/md';
import AppLoading from '../utils/AppLoading';

const EVENT_SAVE_INFO_URL = "/events/save"
const EVENTS_URL = "/events/active";
const EVENT_ARCHIVE_URL = "/events/toggle/status"

const AddOrEditEvent = () => {
  useCheckRouteAuthorization();
  const {t, i18n} = useTranslation();
  const eventInfo = t("eventInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const [errors, setErrors] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [state, _, setChangeData] = useAxiosFetchApi(EVENTS_URL, {}, null);
  // const [archiveModal, setArchiveModal] = useState({event: null, isShown: false});
  const events = state.data?.list;
    const queryEvents = events?.filter((data) =>
    Object.values(data).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    ))
  
    const handleAddOrEditEvent = async(e)=>{
      e.preventDefault();
      const data = new FormData(e.target);
      const formData = Object.fromEntries(data.entries());
      console.log(formData)
      try {
      await utils.validationSchema(eventInfo, events, t("requiredMessage"), selectedEvent)
      .validate(formData, {abortEarly: false});
      setErrors(null)
      const requestBody = {
        ...formData,
        eventId: selectedEvent?selectedEvent?.eventId:null
      }
      const infoResponse = await axiosPrivate.post(EVENT_SAVE_INFO_URL, requestBody,
          {headers: {'Accept-Language': i18n.language}}
      );
      if(!selectedEvent){ 
        setChangeData([...events, infoResponse?.data.data])
      } else {
        const otherEvents = events.filter(event=> event?.eventId !== selectedEvent.eventId);
        setChangeData([...otherEvents, infoResponse?.data.data])
        setSelectedEvent(null)
      }
      e.target.querySelectorAll('input').forEach(input => {
        input.value = '';
      });
      toast.success(infoResponse?.data.message);
    } catch (error) {
      if(error instanceof ValidationError){
        let allErrors = {};
        console.error("validation error...")
        error?.inner?.forEach((err)=>{
          allErrors[err.path]=err.message;
        })
        setErrors(allErrors)
      }
      toast.error(error.response?.data.message)
    }
  }
  const onEditOpened = (event)=> {
    const idSameUpdated = selectedEvent&&selectedEvent.eventId===event.eventId;
    setSelectedEvent(
    idSameUpdated ? null : event)
    document.querySelectorAll('input.event-en-name').forEach(input => {
      input.value = idSameUpdated ? '' : event.enName;
    });
    document.querySelectorAll('input.event-ar-name').forEach(input => {
      input.value = idSameUpdated ? '' : event.arName;
    });
  }

  const handleArchiveEvent = async (eventId)=>{
    try {
      const eventArchivedResponse = await axiosPrivate.get(EVENT_ARCHIVE_URL+`/${eventId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(eventArchivedResponse?.data.message);
      const afterArchived = events.filter(event=> event?.eventId !== eventId);
      setChangeData(afterArchived)
      // setArchiveModal({event: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <div>
      <ToastContainer/>
        <h2>{selectedEvent?eventInfo.editEventTxt:eventInfo.addEventTxt} {selectedEvent&&selectedEvent?.arName}</h2>
        <div className='flex justify-between items-center'>
            <form className='w-full' onSubmit={handleAddOrEditEvent} method='post'>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                    <div className="md:w-1/4">
                      <label htmlFor="arName" className="text-lg">{eventInfo.arName?.label}</label>
                      <input type="text" name="arName" id="arName" defaultValue={selectedEvent?.arName} className="event-ar-name sm:text-sm bg-slate-100 w-full p-2.5" placeholder={eventInfo.arName?.label} />
                      {errors?.arName&&<div className='text-red-600'>{errors?.arName}</div>}
                    </div>
                    <div className="md:w-1/4">
                      <label htmlFor="enName" className="text-lg">{eventInfo.enName?.label}</label>
                      <input type="text" name="enName" id="enName" defaultValue={selectedEvent?.enName} className="event-en-name sm:text-sm bg-slate-100 w-full p-2.5" placeholder={eventInfo.enName?.label} />
                      {errors?.enName&&<div className='text-red-600'>{errors?.enName}</div>}
                    </div>
                    {!selectedEvent&&
                    <div className="md:w-1/4">
                    {/* Don't forget to add/remove items from validation list in EventsUtils  */}
                      <label htmlFor="clonedEventId" className="text-lg">{eventInfo.clonedEvent?.label}</label>
                      <select name="clonedEventId" id="clonedEventId" defaultValue={selectedEvent?.eventId} className="event-info sm:text-sm bg-slate-100 w-full p-2.5" placeholder={eventInfo.clonedEvent?.label}>
                        {events?.map((event)=>{
                          return <option key={event.eventId} value={event.eventId}>{i18n.language==="en"?event.enName:event.arName}</option>
                        })}
                      </select>
                      {errors?.clonedEvent&&<div className='text-red-600'>{errors?.clonedEvent}</div>}
                    </div>}
                </div>
                <div className="flex flex-col md:flex-row flex-wrap justify-between my-6">
                  <button type="submit" className="justify-self-end w-1/4 bg-primary-color text-white px-5 py-2.5 mx-1 my-6">{selectedEvent?t("editBtn"):t("addBtn")}</button>
                </div>
            </form>
        </div>
        <input value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={eventInfo.searchEventTxt} />

        <div className="overflow-x-auto w-full scrollbar-custom">
          <div className="inline-block min-w-full my-10">
              <div className="overflow-hidden">
                  <table className="min-w-full border text-center text-sm">
                  <thead
                      className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                      <tr key={"head-1"}>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">#</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{eventInfo.arName.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{eventInfo.enName.label.replace("*","")}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{eventInfo.exhibitorsCountText}</th>
                      <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                      </tr>
                  </thead>
                  <tbody className='border-b'>
                      {state.isLoading?<tr><td colSpan={15} className='p-10'>
                          <AppLoading height={'h-[10px]'} size={'text-xl'}/>
                          </td></tr>
                      : !events
                      ? <tr><td colSpan={15} className='p-10'>{state?.data?.message??state?.error?.message}</td></tr>
                      : queryEvents?.length===0
                      ? <tr><td colSpan={15} className='p-10'>{eventInfo.noEvents}</td></tr>
                      : queryEvents.map((event)=>{
                      return <tr key={event.eventId} className='border-b'>
                      <td className="whitespace-nowrap border-r p-4">{event.eventId}</td>
                      <td className="whitespace-nowrap border-r p-4">{event.arName}</td>
                      <td className="whitespace-nowrap border-r p-4">{event.enName}</td>
                      <td className="whitespace-nowrap border-r p-4">{event.exhibitors?.length??"_"}</td>
                      <td className="whitespace-nowrap border-r p-4 flex justify-center">
                          <button className={`${selectedEvent&&selectedEvent.eventId===event.eventId
                            ? "bg-white"
                            : 'bg-green-300'} inline-block mx-0.5`} onClick={() => onEditOpened(event)}>
                              <MdEdit size={20}/>
                          </button>
                          <button className='bg-red-300 mx-0.5' onClick={()=> handleArchiveEvent(event.eventId)}>
                            {/* setArchiveModal({event, isShown: true})}> */}
                              <MdArchive size={20}/>
                          </button>
                      </td>
                      {/* <ConfirmationModal
                          key={event?.eventId}
                          title={eventInfo.confirmArchiveTitle}
                          btnColor={"bg-danger"}
                          message={eventInfo.confirmArchiveBody}
                          onAction={handleArchiveEvent}
                          showModal={archiveModal.isShown}
                          btnText={t("confirmArchiveBtn")}
                          setShowModal={(value)=>setArchiveModal({event: null, isShown: value})} /> */}
                      </tr>
                      })}
                  </tbody>
                  </table>
              </div>
          </div>
        </div>
    </div>
  )
}

export default AddOrEditEvent