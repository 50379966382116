import { useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useAuth } from "../hooks/appHooks";

const useCheckRouteAuthorization = () => {

    const screens = useOutletContext();
    const {auth} = useAuth()
    const location = useLocation();
    const navigate = useNavigate();
    
    const routePath = location.pathname.split("/")[1]
    const secondRoutePath = location.pathname.split("/")[2]
    const allowedRoute = screens?.some(screen => screen.route === routePath);
    const secondAllowedRoute = screens?.some(screen => screen.route === secondRoutePath);
    console.log(allowedRoute, secondAllowedRoute, routePath, secondRoutePath, auth.role)
    useEffect(() => {
        let didCancel=false;
        if(auth.role && !didCancel){
            if(auth.role==="SUPERADMIN") {
                if(!routePath || !allowedRoute) {
                    console.log("/exhibitors")
                    navigate("/exhibitors")
                }
            } else if(auth.role==="MANAGER") {
                if(!secondAllowedRoute && !secondRoutePath) {
                    console.log("/super/events")
                    navigate("/super/events")
                }
            } else
                navigate("/unauthorized")
        }
        return () => {
        didCancel=true;
        }
    }, [allowedRoute, navigate, auth.role, routePath, secondAllowedRoute, secondRoutePath])

    return screens;
}

export default useCheckRouteAuthorization