import React, { useEffect, useState } from 'react'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useExhibitorsList } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../apis/axios';

const ExhibitorAgreements = () => {
  useCheckRouteAuthorization();
  const {exhibitors} = useExhibitorsList()
  const {t} = useTranslation();
  const navigate = useNavigate();
  const agreementInfo = t("agreementInfo");
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    if(!exhibitors){
        navigate("/exhibitors")
    }
  }, [navigate, exhibitors])
  
  const exhibitorsAgreements = exhibitors?.flatMap(exhibitor => exhibitor?.agreements);
  const agreements = exhibitorsAgreements?.filter((data) =>
        Object.values(data).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )

  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{agreementInfo.agreementsTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={agreementInfo.searchAgreementTxt} />
            </div>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{agreementInfo.title.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{agreementInfo.signatory.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{agreementInfo.cosigner.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{agreementInfo.notes.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{agreementInfo.cost.label.replace("*","")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {
                        agreements?.length===0
                        ? <tr><td colSpan={11} className='p-10'>{agreementInfo.noAgreements}</td></tr>
                        : agreements?.map((agreement)=>{
                        return <tr key={agreement.agreementId} className='border-b'>
                        <td className="whitespace-nowrap border-r p-4">{agreement.title??"_"} </td>
                        <td className="whitespace-nowrap border-r p-4">
                            <div className='flex items-center justify-around'>
                                {agreement.signatoryLogo
                                ? <div className='w-20 mx-2'>
                                    <img width={"100%"} src={`${BASE_URL}/files/get/file/${agreement.signatoryLogo}`} alt={agreement.signatory} />
                                  </div>
                                : ""}<p className='text-lg'>{agreement.signatory}</p>
                            </div>
                        </td>
                        <td className="whitespace-nowrap border-r p-4">
                            <div className='flex items-center justify-around'>
                                {agreement.cosignerLogo
                                ? <div className='w-20 mx-2'>
                                    <img width={"100%"} src={`${BASE_URL}/files/get/file/${agreement.cosignerLogo}`} alt={agreement.cosigner} />
                                  </div>
                                : ""}<p className='text-lg'>{agreement.cosigner}</p>
                            </div>
                        </td>
                        <td className="border-r p-4">{agreement.notes??"_"}</td>
                        <td className="whitespace-nowrap border-r p-4">{agreement.cost??"_"}</td>
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExhibitorAgreements