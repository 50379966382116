import { createContext, useState } from "react";

const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
    const token = localStorage.getItem("ecms_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const [auth, setAuth] = useState({token, refreshToken})

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext