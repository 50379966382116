import React from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from './LanguageSelector'

const RoleSelector = ({isManager=true, onRoleChanged}) => {
  const {t} = useTranslation();

  return (
    <div className='flex justify-between text-sm'>
    <div className='flex'>
      <button onClick={()=>onRoleChanged(false)} className={`flex ${!isManager&&"text-white bg-primary-color"} justify-center`}>
        {t("adminRoleTxt")}
      </button>
      <button onClick={()=>onRoleChanged(true)} className={`flex  ${isManager&&"text-white bg-primary-color"} justify-center`}>
        {t("managerRoleTxt")}
      </button>
    </div>
    <LanguageSelector/>
    </div>
  )
}

export default RoleSelector