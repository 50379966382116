import React, { useEffect, useState } from 'react'
import { MdDelete, MdDownload, MdEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useAuth } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import AppLoading from '../utils/AppLoading';
import useAxiosFetchApi from '../hooks/useFetch';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate, BASE_URL } from '../apis/axios';
import ConfirmationModal from './modals/ConfirmationModal';
const EXHIBITOR_FILES_LIST_URL = '/exhibitorFiles/event'
const EXHIBITOR_FILE_DELETE_URL = '/exhibitorFiles/delete'

const ExhibitorFiles = () => {
  useCheckRouteAuthorization();
  const {auth} = useAuth()
  const {t, i18n} = useTranslation();
  const [deleteModal, setDeleteModal] = useState({file: null, isShown: false});
  const fileInfo = t("fileInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const [state, setUrl, setChangeData] = useAxiosFetchApi(null, {}, auth.token);
  const exhibitorFiles = state.data?.list;
  useEffect(()=>{
    if(auth.event){
      setUrl(EXHIBITOR_FILES_LIST_URL.concat(`/${auth.event?.eventId}`))
    }
  },[setUrl, auth.event])

  const handleDeleteExhibitorFile = async ()=>{
    console.log(deleteModal.file?.arTitle)
    try {
      const fileDeletedResponse = await axiosPrivate.delete(EXHIBITOR_FILE_DELETE_URL+`/${deleteModal.file?.fileId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(fileDeletedResponse?.data.message);
      const afterDeleted = exhibitorFiles.filter(file=> file.fileId !== deleteModal.file?.fileId);
      setChangeData(afterDeleted)
      setDeleteModal({file: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  }
    const files = exhibitorFiles?.filter((data) =>
        Object.values(data).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )
  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{fileInfo.exhibitorFileTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={fileInfo.searchFileTxt} />
            </div>
            <Link className='bg-primary-color text-white p-2 text-sm'
            to={"/exhibitor-files/add"}>
                {fileInfo.addFileTitle}
            </Link>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        {i18n.language==="ar"
                        ? <th scope="col" className="whitespace-nowrap p-4 border-r">{fileInfo.arTitle.label.replace("*","")}</th>
                        : <th scope="col" className="whitespace-nowrap p-4 border-r">{fileInfo.enTitle.label.replace("*","")}</th>}
                        <th scope="col" className="whitespace-nowrap p-1 border-r">{fileInfo.dueDate.label.replace("*","")}</th>
                        {/* <th scope="col" className="whitespace-nowrap p-1 border-r">{fileInfo.fileType.label.replace("*","")}</th> */}
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{fileInfo.guidesFile.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{i18n.language==="ar"?"النوع":"Type"}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {state.isLoading?<tr><td colSpan={7} className='p-10'>
                            <AppLoading height={'h-[10px]'}/>
                            </td></tr>
                        : !exhibitorFiles
                        ? <tr><td colSpan={7} className='p-10'>{state?.data?.message??state?.error?.message}</td></tr>
                        : files?.length===0
                        ? <tr><td colSpan={9} className='p-10'>{fileInfo.noFiles}</td></tr>
                        : files?.map((exhibitorFile)=>{
                            // const bgColor=exhibitorFile.status==="APPROVED"?"bg-green-200":"bg-red-200";
                            // const txtColor=exhibitorFile.status==="APPROVED"?"text-green-600":"text-red-600";
                        return <tr key={exhibitorFile.fileId} className='border-b'>
                        {i18n.language==="ar"
                        ? <td className="whitespace-nowrap border-r p-4">{exhibitorFile.arTitle}</td>
                        : <td className="whitespace-nowrap border-r p-4">{exhibitorFile.enTitle}</td>}
                        <td className="whitespace-nowrap border-r p-4">{exhibitorFile.ftdueDate}</td>
                        {/* <td className="whitespace-nowrap border-r p-4">{exhibitorFile.fileType}</td> */}
                        <td className="whitespace-nowrap border-r p-4">
                            <MdDownload className='inline-block primary-color mx-1'/>
                            <a href={`${BASE_URL}/files/get/file/${exhibitorFile.guidesFile}`} target="_blank" rel="noopener noreferrer" className='normal-link'>{t("downloadTxt")}</a>
                        </td>
                        <td className="whitespace-nowrap border-r py-4">
                            {exhibitorFile.optional?
                            i18n.language==="ar"?"إختياري":"optional":
                            i18n.language==="ar"?"إجباري":"mandatory"}</td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-center">
                            <Link className='bg-green-300 inline-block mx-0.5'
                            to={"/exhibitor-files/edit"} state={{exhibitorFile}}>
                                <MdEdit size={17}/>
                            </Link>
                            <button className='bg-red-300 mx-0.5' onClick={()=> setDeleteModal({file: exhibitorFile, isShown: true})}>
                                <MdDelete size={17}/>
                            </button>
                        </td>
                        <ConfirmationModal
                            key={exhibitorFile?.fileId}
                            title={fileInfo.confirmDeleteTitle}
                            btnColor={"bg-danger"}
                            message={fileInfo.confirmDeleteBody}
                            onAction={handleDeleteExhibitorFile}
                            showModal={deleteModal.isShown}
                            setShowModal={(value)=>setDeleteModal({file: null, isShown: value})} />
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
{/* 
        <div className="relative files-card w-full mx-10 text-xs h-44 lg:text-lg lg:w-[43%] lg:mx-8 my-16 rounded-xl">
            <div className="absolute z-20 rounded-xl bg-gray-50 p-3 h-full top-6 w-full shadow-lg">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <h3>Rigging Commitment Letter</h3>
                    <span className='bg-slate-800 p-1 my-2 lg:my-0 text-sm rounded-lg text-white'>Optional</span>
                </div>

                <p className='px-3'>Due Date : <small>10/12/2023</small></p>
                <hr className='my-3'/>
                <div className="flex flex-col lg:flex-row justify-between items-center">
                    <p className='border border-primary-color primary-color p-1 text-center w-[120px] rounded-xl'>SPACE ONLY</p>
                    <p>salam@restatex.com</p>
                </div>
                <div className='actions flex justify-between items-center mt-4'>
                    <Link className='bg-primary-color rounded-md p-2 -ml-10 -mb-10'>
                        <MdEdit size={30} className='text-white'/>
                    </Link>
                    <Link className='bg-primary-color rounded-md p-2 -mb-10'>
                        <MdDelete size={30} className='text-white'/>
                    </Link>
                    <Link className='bg-primary-color rounded-md p-2 -mr-10 -mb-10'>
                        <MdDownload size={30} className='text-white'/>
                    </Link>
                </div>
            </div>
        </div>

        <div className="relative files-card w-full mx-10 text-xs h-44 lg:text-lg lg:w-[43%] lg:mx-8 my-16 rounded-xl">
            <div className="absolute z-20 rounded-xl bg-gray-50 p-3 h-full top-6 w-full shadow-lg">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <h3>Agreement signing form</h3>
                    <span className='bg-slate-800 p-1 my-2 lg:my-0 text-sm rounded-lg text-white'>Optional</span>
                </div>

                <p className='px-3'>Due Date : <small>10/12/2023</small></p>
                <hr className='my-3'/>
                <div className="flex flex-col lg:flex-row justify-between items-center">
                    <p className='border border-primary-color primary-color p-1 text-center w-[120px] rounded-xl'>SPACE ONLY</p>
                    <p>salam@restatex.com</p>
                </div>
                <div className='actions flex justify-between items-center mt-4'>
                    <Link className='bg-primary-color rounded-md p-2 -ml-10 -mb-10'>
                        <MdEdit size={30} className='text-white'/>
                    </Link>
                    <Link className='bg-primary-color rounded-md p-2 -mb-10'>
                        <MdDelete size={30} className='text-white'/>
                    </Link>
                    <Link className='bg-primary-color rounded-md p-2 -mr-10 -mb-10'>
                        <MdDownload size={30} className='text-white'/>
                    </Link>
                </div>
            </div>
        </div>
        <div className="relative files-card w-full mx-10 text-xs h-44 lg:text-lg lg:w-[43%] lg:mx-8 my-16 rounded-xl">
            <div className="absolute z-20 rounded-xl bg-gray-50 p-3 h-full top-6 w-full shadow-lg">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                    <h3>Exhibition guide pages information</h3>
                    <span className='bg-slate-800 p-1 my-2 lg:my-0 text-sm rounded-lg text-white'>Mandatory</span>
                </div>

                <p className='px-3'>Due Date : <small>10-25-2023</small></p>
                <hr className='my-3'/>
                <div className="flex flex-col lg:flex-row justify-between items-center">
                    <p className='border border-primary-color primary-color p-1 text-center w-[120px] rounded-xl'>
                        SPACE ONLY
                    </p>
                    <p>salam@restatex.com</p>
                </div>
                <div className='actions flex justify-between items-center mt-4'>
                    <Link className='bg-primary-color rounded-md p-2 -ml-10 -mb-10'>
                        <MdEdit size={30} className='text-white'/>
                    </Link>
                    <Link className='bg-primary-color rounded-md p-2 -mb-10'>
                        <MdDelete size={30} className='text-white'/>
                    </Link>
                    <Link className='bg-primary-color rounded-md p-2 -mr-10 -mb-10'>
                        <MdDownload size={30} className='text-white'/>
                    </Link>
                </div>
            </div>
        </div>         */}
    </div>
  )
}

export default ExhibitorFiles