import { useContext } from "react"
import AuthContext from "../provider/AuthProvider"
import ExhibitorsListContext from "../provider/ExhibitorsListProvider"

export const useAuth = () => {
    return useContext(AuthContext)
}

export const useExhibitorsList = () => {
    return useContext(ExhibitorsListContext)
}