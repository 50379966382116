import React, { useState, useEffect } from 'react'
import {MdDownload } from 'react-icons/md'
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useExhibitorsList } from '../hooks/appHooks';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { axiosPrivate } from '../apis/axios';
import { useParams } from 'react-router-dom';
const EXHIBITOR_BADGE_STATUS_URL = '/badges/change/status'

const ExhibitorBadges = () => {
  useCheckRouteAuthorization();
  const {exhibitors, setExhibitorsList} = useExhibitorsList()
  const {t, i18n} = useTranslation();
  const badgeInfo = t("badgeInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const {exhibitorId} = useParams();
  const exhibitor = exhibitors?.find((e)=>e.exhibitorId===Number(exhibitorId));
  const exhibitorBadges = exhibitor?.badges;

  // Load exhibitors from localStorage if available
  useEffect(() => {
    const storedExhibitors = localStorage.getItem('exhibitorsState');
    if (storedExhibitors && !exhibitors) {
      setExhibitorsList(JSON.parse(storedExhibitors));
    }
  }, [setExhibitorsList, exhibitors]);

  const handleChangeBadgeStatus = async (badgeId, status)=>{
    try {
      const statusChangedResponse = await axiosPrivate.post(EXHIBITOR_BADGE_STATUS_URL, {id: badgeId, status},
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );

      toast.success(statusChangedResponse?.data.message);
      const otherBadges = exhibitorBadges.filter(badge=> badge.badgeId !== badgeId);
      const otherExhibitors = exhibitors.filter(e=> e.exhibitorId !== Number(exhibitorId));
      const oldBadge = exhibitorBadges.find(badge=> badge.badgeId === badgeId);
      setExhibitorsList(
        [...otherExhibitors, {...exhibitor, badges: [...otherBadges, {...oldBadge, status: statusChangedResponse.data?.data}]}]
    )
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  }
    const badges = exhibitorBadges?.filter((data) =>
        Object.values(data).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )

  const handleDownload = (badgeId) => {
    // Store the current exhibitors state before opening the new tab
    localStorage.setItem('exhibitors', JSON.stringify(exhibitors));
    window.open(`/${badgeId}.pdf`, '_blank');
  }

  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2'>
                <h2>{badgeInfo.badgesTitle}</h2>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={badgeInfo.searchBadgeTxt} />
            </div>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.firstName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.lastName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.jobTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.companyName.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.city.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.phone.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.email.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{badgeInfo.type.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("downloadTxt")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {
                        badges?.length===0
                        ? <tr><td colSpan={11} className='p-10'>{badgeInfo.noBadges}</td></tr>
                        : badges?.map((badge)=>{
                        return <tr key={badge.badgeId} className='border-b'>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.firstName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.lastName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.jobTitle?badge.jobTitle:"_"}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.companyName}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.city?badge.city:"_"}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.phone?badge.phone:"_"}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.email?badge.email:"_"}</td>
                        <td className="whitespace-nowrap text-start border-r p-4">{badge.type?badge.type:"_"}</td>
                        <td className="whitespace-nowrap border-r p-4">
                            <MdDownload className='inline-block primary-color mx-1'/>
                            <button onClick={() => handleDownload(badge.badgeId)} className='normal-link'>{t("downloadTxt")}</button>
                        </td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-end">
                            {badge.status==="PENDING" &&
                            <button className='bg-green-300 mx-0.5' onClick={()=> handleChangeBadgeStatus(badge.badgeId, "APPROVED")}>
                                موافقة
                            </button>}
                            {badge.status==="PENDING" &&
                            <button className='bg-red-300 mx-0.5' onClick={()=> handleChangeBadgeStatus(badge.badgeId, "REJECTED")}>
                                رفض
                            </button>}
                            {badge.status!=="PENDING" &&
                            <button className='bg-yellow-300 mx-0.5' onClick={()=> handleChangeBadgeStatus(badge.badgeId, "PENDING")}>
                                إعادة تعيين
                            </button>}
                        </td>
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExhibitorBadges