import React from 'react'
import { useTranslation } from 'react-i18next';

const AppLoading = ({height, size, label}) => {
  const{t} = useTranslation();
  return (
    <div className={`cairo-font flex justify-center items-center ${height??'h-[100vh]'}`}>
      <p className={`stroke-zinc-800 ${size??'text-4xl'}`}>{label??t("nowLoadingTxt")}</p>
    </div>
  )
}

export default AppLoading