import *as Yup from 'yup'

export const initConstentDescription = (content)=>{
    return {
        arDescription: content?content?.arDescription:null,
        enDescription: content?content?.enDescription:null,
    }
}

export const validationSchema =(fileTexts, isEditing, requiredMessage)=> {return Yup.object({
    arTitle: Yup.string().required(requiredMessage),
    enTitle: Yup.string().required(requiredMessage),
    dueDate: Yup.string().required(requiredMessage),
    // receiveEmail: Yup.string().email(fileTexts.receiveEmail.emailFormat).nullable(),
    optional: Yup.boolean().required(requiredMessage),
    // extension: Yup.string()
    // .oneOf([".pdf,.pdf", ".xlsx,.xls", ".doc,.docx"], fileTexts?.extension.invalidType)
    // .required(requiredMessage),
    // fileType: Yup.string()
    // .oneOf(["SPACE_ONLY", "STANDARD_BOOTH", "OFFICIAL", "DIAMOND", "PLATINUM", "GOLDEN", "SILVER", "CO"], fileTexts?.fileType.invalidType)
    // .required(requiredMessage),
    guidesFile: Yup.mixed().when([], {
    is: () => !isEditing,
    then: ()=> Yup.mixed()
      .test(
        'fileSize',
        'حجم الصورة غير مدعوم',
        value => value && value.size <= 1024 * 1024 * 25 // 25 MB
      )
      .test(
        'fileType',
        `الملفات المدعومة pdf, xlsx and docx`,
        value => value && [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ].includes(value.type)
      ),
      otherwise: ()=> Yup.mixed().notRequired(), // No validation when editing
    }),
})}

export const fillExhibitorFileData=(exhibitorFileData, formData, eventId, fileId)=>{
    const formattedDate = formData.dueDate.replace('T', ' ').concat(":00");
    console.log("Due Date:", formData.dueDate);

    exhibitorFileData.append("ExhibitorFilePayload", JSON.stringify({
        fileId, eventId,
        arTitle: formData.arTitle,
        enTitle: formData.enTitle,
        dueDate: formattedDate,
        // receiveEmail: formData.receiveEmail!==""?formData.receiveEmail:null,
        optional: formData.optional,
        // extension: formData.extension,
        // fileType: formData.fileType
      }));
      exhibitorFileData.append("GuidesFile", formData.guidesFile);
}