
import { React, useEffect, useState } from 'react';
import { Collapse, Ripple, initTE } from "tw-elements";
import useAxiosFetchApi from '../hooks/useFetch';
import AppLoading from '../utils/AppLoading';
import { useAuth } from '../hooks/appHooks';
import useCheckRouteAuthorization from '../apis/useCheckRouteAuthorization';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import ConfirmationModal from './modals/ConfirmationModal';
import { toast, ToastContainer } from 'react-toastify';
import { axiosPrivate } from '../apis/axios';

initTE({ Collapse, Ripple });
const SCREEN_CONTENTS_URL = '/contents'
const CONTENT_DELETE_URL = '/contents/delete'

const EventDataContents = () => {

  useCheckRouteAuthorization();
  const {screenId} = useParams();
  const{t, i18n} = useTranslation();
  const contentInfo = t("contentInfo");
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteModal, setDeleteModal] = useState({content: null, isShown: false});
  const { auth } = useAuth();
  const [state,_ , setChangeData] = useAxiosFetchApi(SCREEN_CONTENTS_URL, {}, auth.token);
  const allContents = state.data?.list?.filter(content=> content.screen.screenId === Number(screenId));
  useEffect(()=>{
    initTE({ Collapse, Ripple });
  })

  const handleDeleteContent = async ()=>{
    
    try {
      const contentDeletedResponse = await axiosPrivate.delete(CONTENT_DELETE_URL+`/${deleteModal.content?.contentId}`,
        {headers: { 'Accept-Language': i18n.language, 'Content-Type': 'application/json'}}
      );
      toast.success(contentDeletedResponse?.data.message);
      const afterDeleted = allContents.filter(content=> content.contentId !== deleteModal.content?.contentId);
      setChangeData(afterDeleted)
      setDeleteModal({content: null, isShown: false})
    } catch (error) {
      toast.error(error.response.data?.message);
    }
  }

  const contents = allContents?.filter((data) =>
    Object.values(data).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  )
  
  return (
    <div className='flex flex-wrap justify-center'>
        <ToastContainer />
        <div className="flex justify-between w-full">
            <div className='flex w-1/2 items-center'>
                <h4>{contentInfo.contentsTitle} {contents ? i18n.language==='ar'? contents[0]?.screen.arTitle:contents[0]?.screen.enTitle:""}</h4>
                <input value={searchQuery}
                   onChange={(e) => setSearchQuery(e.target.value)}
                   className='outline-1 outline-dashed outline-gray-400 text-sm shadow-2 p-2 w-1/2 mx-4' type="text" placeholder={contentInfo.searchContentTxt} />
            </div>
            <Link className='bg-primary-color text-white p-2 text-sm'
              to={"/event-data/contents/add"} state={{screenId: Number(screenId)}}>
                  {contentInfo.addContentTxt}
            </Link>
        </div>
        <div className="overflow-x-auto w-full">
            <div className="inline-block min-w-full my-10">
                <div className="overflow-hidden">
                    <table className="min-w-full border text-center text-sm">
                    <thead
                        className="bg-neutral-100 border-b rounded-lg font-medium dark:border-neutral-500 dark:text-neutral-800">
                        <tr key={"head-1"}>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{i18n.language==='ar' 
                        ? contentInfo.arTitle.label.replace("*","")
                        : contentInfo.enTitle.label.replace("*","")}</th>
                        <th scope="col" className="whitespace-nowrap p-4 border-r">{t("actionTxt")}</th>
                        </tr>
                    </thead>
                    <tbody className='border-b'>
                        {state.isLoading?<tr><td colSpan={7} className='p-10'>
                            <AppLoading/>
                            </td></tr>
                        : !contents
                        ? <tr><td colSpan={11} className='p-10'>{state?.data?.message??state?.error?.message}</td></tr>
                        : contents?.length===0
                        ? <tr><td colSpan={11} className='p-10'>{contentInfo.noContents}</td></tr>
                        : contents?.map((content)=>{
                        return <tr key={content.contentId} className='border-b'>
                        <td className="whitespace-nowrap text-start border-r p-4">{i18n.language==='ar' ? content.arTitle : content.enTitle}</td>
                        <td className="whitespace-nowrap border-r py-4 flex justify-center">
                            <Link className='bg-green-300 inline-block mx-0.5'
                              to={"/event-data/contents/edit"} state={{content, screenId: Number(screenId)}}>
                                  <MdEdit size={17}/>
                            </Link>
                            <button className='bg-red-300 mx-0.5' onClick={()=> setDeleteModal({content, isShown: true})}>
                                <MdDelete size={17}/>
                            </button>
                        </td>
                        <ConfirmationModal
                            key={content?.contentId}
                            title={contentInfo.confirmDeleteTitle}
                            btnColor={"bg-danger"}
                            message={contentInfo.confirmDeleteBody}
                            onAction={handleDeleteContent}
                            showModal={deleteModal.isShown}
                            setShowModal={(value)=>setDeleteModal({content: null, isShown: value})} />
                        </tr>
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EventDataContents